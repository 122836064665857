import React, { Fragment } from "react";
import moment from "moment";

const Footer = (props) => {
  return (
    <Fragment>
      <footer
        style={{ background: "#303031", color: "#87898A", fontSize: "0.85rem" }}
        className="z-10 py-6 px-4 md:px-12 text-center"
      >
        <div>
          <p>Phone: +216 29 703 323</p>
          <p>
            Facebook:{" "}
            <a
              href="https://www.facebook.com/Electrojannet/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#87CEEB" }}
            >
              Electro Jannet
            </a>
          </p>
          <p>
            Email:{" "}
            <a
              href="mailto:electrojannet@gmail.com"
              style={{ color: "#87CEEB" }}
            >
              electrojannet@gmail.com
            </a>
          </p>
          <p>
            Location: Messadine, Msaken, Sousse, Tunisia
          </p>
          <p>
            <strong>English:</strong> Electro Jannet is a distinguished member of the Brand Space Boutique Series, offering an extensive selection of products tailored to diverse needs. Our collection features stylish shoes, fashionable clothing, top-quality home appliances, premium beauty products, and sophisticated aesthetic solutions. Additionally, we provide a variety of electronics, kitchenware, furniture, and health & wellness products, ensuring you find exactly what you need to enhance your lifestyle. Explore our range in-store or online for exceptional quality and personalized service.
          </p>
          <p>
            <strong>Français:</strong> Electro Jannet est un membre distingué de la série des Boutiques Brand Space, offrant une large sélection de produits adaptés à divers besoins. Notre collection comprend des chaussures élégantes, des vêtements à la mode, des appareils électroménagers de haute qualité, des produits de beauté haut de gamme et des solutions esthétiques sophistiquées. De plus, nous proposons une variété d'électroniques, d'ustensiles de cuisine, de meubles et de produits pour la santé et le bien-être, garantissant que vous trouviez exactement ce dont vous avez besoin pour améliorer votre style de vie. Découvrez notre gamme en magasin ou en ligne pour une qualité exceptionnelle et un service personnalisé.
          </p>
          <p>
            <strong>العربية:</strong> إلكترو جانيت هي عضو مميز في سلسلة بوتيكات براند سبيس، وتقدم مجموعة واسعة من المنتجات المصممة لتلبية احتياجات متنوعة. تشمل تشكيلتنا أحذية أنيقة، ملابس عصرية، أجهزة منزلية عالية الجودة، منتجات تجميل ممتازة، وحلول جمالية متطورة. بالإضافة إلى ذلك، نقدم مجموعة متنوعة من الإلكترونيات، أدوات المطبخ، الأثاث، ومنتجات الصحة والعافية، مما يضمن لك العثور على ما تحتاجه لتحسين نمط حياتك. استكشف تشكيلتنا في المتجر أو عبر الإنترنت للحصول على جودة استثنائية وخدمة مخصصة.
          </p>
          <p>
            <strong>English:</strong> Discover the latest trends and exclusive products at our stores in Messadine, Msaken, and Sousse. Whether you’re shopping for the newest in footwear, fashion, home essentials, or premium beauty products, Electro Jannet is your go-to destination for quality and style. Our team is dedicated to providing exceptional customer service and helping you find exactly what you're looking for. Visit us today to experience unparalleled service and a curated selection of high-end products.
          </p>
          <p>
            <strong>Français:</strong> Découvrez les dernières tendances et produits exclusifs dans nos magasins à Messadine, Msaken et Sousse. Que vous cherchiez les nouveautés en matière de chaussures, de mode, d'essentiels pour la maison ou de produits de beauté haut de gamme, Electro Jannet est votre destination idéale pour la qualité et le style. Notre équipe se consacre à offrir un service client exceptionnel et à vous aider à trouver exactement ce que vous recherchez. Venez nous rendre visite aujourd'hui pour découvrir un service inégalé et une sélection soignée de produits haut de gamme.
          </p>
          <p>
            <strong>العربية:</strong> اكتشف أحدث الاتجاهات والمنتجات الحصرية في متاجرنا في مسعدين، مساكن، وسوسة. سواء كنت تبحث عن أحدث صيحات الأحذية، الموضة، الأساسيات المنزلية، أو منتجات التجميل الراقية، فإن إلكترو جانيت هي وجهتك المثالية للجودة والأناقة. فريقنا مكرس لتقديم خدمة عملاء استثنائية ومساعدتك في العثور على ما تبحث عنه. زورنا اليوم لتجربة خدمة لا مثيل لها وتشكيلة مختارة من المنتجات الراقية.
          </p>
          <p>
            <strong>English:</strong> Visit our online shop for a seamless shopping experience. Browse our diverse range of products, from electronics to home goods, and enjoy the convenience of shopping from the comfort of your home. Our commitment to quality and customer satisfaction ensures that you receive only the best. Contact us for any inquiries or assistance, and our friendly staff will be happy to help.
          </p>
          <p>
            <strong>Français:</strong> Visitez notre boutique en ligne pour une expérience de shopping fluide. Parcourez notre gamme diversifiée de produits, des électroniques aux articles pour la maison, et profitez de la commodité d'acheter depuis le confort de chez vous. Notre engagement envers la qualité et la satisfaction client garantit que vous ne recevrez que le meilleur. Contactez-nous pour toute demande ou assistance, et notre personnel amical sera heureux de vous aider.
          </p>
          <p>
            <strong>العربية:</strong> زر متجرنا عبر الإنترنت للحصول على تجربة تسوق سلسة. تصفح مجموعتنا المتنوعة من المنتجات، من الإلكترونيات إلى مستلزمات المنزل، واستمتع براحة التسوق من منزلك. إن التزامنا بالجودة ورضا العملاء يضمن لك الحصول على الأفضل فقط. اتصل بنا لأي استفسارات أو مساعدة، وسيسعد موظفونا الودودون بمساعدتك.
          </p>
          <p>Brand Space © Copyright {moment().format("YYYY")}</p>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
